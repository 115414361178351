// 3rd party
import React, {useContext, useEffect} from "react"
import {useStaticQuery, graphql as gql} from "gatsby"
import styled from "styled-components"

// components
import Seo from "../components/general/seo"
import FadeInImage from "../components/general/fadeInImage"

// context, utils
import {GlobalDispatchContext} from "../context/GlobalContextProvider"
import {getAspectRatio} from "../components/splash/utils/getAspectRatio"
import {getNumDenom} from "../lib/utils"

export default function Home() {
  // state
  const appDispatch = useContext(GlobalDispatchContext)

  // queries
  let data = useStaticQuery(QUERY_LANDING_PAGE_IMAGE)

  // data
  let image = data.wp.themeGeneralSettings.about.image

  // effect
  useEffect(() => {
    let pageTemplate = {medium: 0, project: 0}
    let page = {main: pageTemplate, sub: pageTemplate, currentId: 0, template: "", type: ""}

    appDispatch({type: "setPage", value: {type: "medium", page: page}})
    appDispatch({type: "setAttached", value: {items: [], currentId: 0}})
  }, [appDispatch])

  let aspectRatio = getAspectRatio(image.mediaDetails)
  let [numerator, denominator] = getNumDenom(Math.round(aspectRatio * 100) / 100)
  let sizes = `(min-aspect-ratio: ${numerator}/${denominator}) ${aspectRatio * 100}vh, 100vw`

  return (
    <>
      <Seo title="Home" />
      {image && (
        <Wrapper>
          <FadeInImage src={image.sourceUrl} srcSet={image.srcSet} sizes={sizes} style={{objectFit: "cover", height: "100%"}} />
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  height: 100vh; //calc(100vh - calc(100vh - 100%));
`

const QUERY_LANDING_PAGE_IMAGE = gql`
  query GetLandingPageImage {
    wp {
      themeGeneralSettings {
        about {
          image: landingPageImage {
            sourceUrl
            srcSet
            sizes
            ...mediaDetails
            ...focalPoint
          }
        }
      }
    }
  }
`
